@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&family=Space+Grotesk:wght@300;400&display=swap');
.navi {
    text-decoration: none;
    color: #112D4E;
}
.navi:hover {
    color: #112D4E;
}


h1 {
  color: #112D4E;
  font-size: 40px;
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
  text-decoration: none;
}

h3 {
  color: #112D4E;
  font-size: 30px;
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
  text-decoration: none;
  text-align: center;  
}

h6 {
  color: #112D4E;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  text-decoration: none;
  text-align: center;
}

h5 {
  color: hsl(212, 64%, 19%);
  font-size: 25px;
  font-weight: 550;
  font-family: 'Space Grotesk', sans-serif;
  text-decoration: none;
}

p {
  color: #112D4E;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Space Grotesk', sans-serif;
    text-decoration: none;
    text-align: justify;
    margin-top: 3%;
}

/* ========= navbar ========= */

.logo {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 576px) {
    .logo {
      max-width: 80%;
    }
  }

  @media (max-width: 576px) {
  .navbtn{
    position: "absolute";
    top: "0";
    right: "0";
    }
  }

.nav-item {
    padding: 7px;
    font-size: 20px;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
}
.nav-link:hover {
  border-bottom: 1px solid #112D4E;
  color: #112D4E;
}
.navbar {
    position: sticky;
    top: 0px;
    z-index: 1000;
    background: white;
    box-shadow: 1px 3px 7px #1111110f;
}

@media (max-width: 576px) {
.logoname{
 font-size: large;
 width: 10rem;
}
}


/* ========= carousel ========= */

.car-img{
    width: 100;
    height: 800px ;
    overflow: hidden;
}


@media (max-width: 1400px) {
  .car-img {
      height: 530px ;
  }
}

@media (max-width: 576px) {
    .car-img {
        height: 200px ;
    }
  }

.carousel-caption {
    margin-left: 0%;
    text-align: left;
    left: 5%;
    right: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    padding-left: 2%;
    
}

.carousel-caption:hover {

  background-color: rgba(0, 0, 0, 0.4);

}


/* ========= About Page ========= */

.about {
    font-size: 20px;
    margin-top: 5%;
    font-weight: 300;

   
}
.des {
    margin-top: 2%;
    margin-bottom: 3%;
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
}

@media (max-width: 576px) {
  .des {
    font-size: 15px;
    font-weight: 700;
    padding: 2%;
    padding-top: 0%;
  }
}


/* ========= Footer ========= */
.foothead{
    margin-top: 3%;
    color: #112D4E;
    font-family: 'Space Grotesk', sans-serif;
}

.foot{
    margin-top: 3%;
    color: #F9F7F7;
    font-size: 15px;
    font-weight: 300;
    font-family: 'Space Grotesk', sans-serif;
    text-decoration: none;
}

.icon {
    height: 40px;
    margin-top: 4%;
    margin-left: 10%;
}
@media (max-width: 576px) {
    .icon {
      max-width: 80%;
      margin-left: 8%;
      margin-top: 4%;
      margin-bottom: 4%;
    }
  }

.iconfi {
    height: 40px;
    margin-left: 0%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.icon:hover {
    height: 60px;
    
}

.iconfi:hover {
    height: 60px;
    
}

.foot:hover {
    font-size: 17px;
    
}

.footerd{
    margin-top: 0%;
    color: #3F72AF; 

}

/* ========= About ========= */

.containerabout {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


/* ========= Text Carousel ========= */

.carouseltext-indicators {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carouseltext-indicators button {
    background-color: #bbb; /* Inactive indicator color */
    border: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px; /* Adjust the margin as needed */
    transition: background-color 0.3s ease; /* Smooth transition effect */
  }
  
  .carouseltext-indicators .active {
    background-color: #112D4E; /* Active indicator color */
  }


/* ========= Contact Page ========= */

.form-group {
  margin-bottom: 4%;
  
}
.form-padding {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .p-md-5 {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
    padding-left: 0%;
    padding-right: 0%;
  }
}

.icon-big {
  height: 50px;
  margin-right: 10%;
}
@media (max-width: 576px) {
  .icon-big {
    max-width: 80%;
  }
}

.fiverr-container{
  width: 170px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 0%;

}
@media (max-width: 576px) {
  .fiverr-container {
    max-height: 80%;
    margin-left: 0%;

  }
}

.contact-item{
  margin-top: 0%;
  margin-left: 0%;
  color: #112D4E;
  font-size: 25px;
  font-weight: 300;
  font-family: 'Space Grotesk', sans-serif;
  text-decoration: none;
}
@media (max-width: 576px) {
  .contact-item {
    font-size: 20px;

  }
}

/* ========= Home Services ========= */

.ser-img{
  width: 200px;
  height: 200px ;
  overflow: hidden;
  text-align: center;
  margin-bottom: 5%;
  margin-top: 5%;
  border-radius: 50%;
  border: 3px solid ;
  border-color: #112D4E;
}

.ser-card{
  border-color: #ffffff;
  margin-bottom: 0%;
}
.ser-card:hover{
  border-color: #112D4E;
}


/* ========= Review ========= */


.rev-img{
  width: 100px;
  height: 100px ;
  overflow: hidden;
  margin-top: 1%;
  border-radius: 50%;
  border: 1px solid ;
  border-color: #112D4E;
}

/* ========= Portfolio ========= */

.middle-line {
  border-left: 3px solid black; /* Set the border properties to create a line */
  height: 100%; /* Make the line take the full height of the column */
  margin-left: 50%;
}



/* ========= Blog ========= */

.blog {
	padding-top: 70px;
	padding-bottom: 80px;
}

.blog__item {
	margin-bottom: 35px;
}

.blog__item__pic {
	height: 240px;
}

.blog__item__pic.large__item {
	height: 540px;
}






